import React, { useState, useContext } from 'react';
import Web3 from 'web3';
import { checkMetamaskSession, connectMetamask } from './utils/inits';
import './App.css';
import PlaceOrder from './components/PlaceOrder';
import Graph from './components/Graph';
import Reimburse from './components/Reimburse';
import ConnectWallet from './components/modal/connectWallet';
import { mainback_1, top_right, state, bottom } from './assets';
import { observer } from 'mobx-react-lite';
import { useAccount } from 'wagmi';

import { StoreContext } from './utils/store';

function App() {
  const { address, isConnected } = useAccount();

  const [change, setChange] = useState(false);

  const [modalOpen, setModalOpen] = useState(false);

  const store = useContext(StoreContext);

  const { loading } = store;

  React.useEffect(() => {
    checkMetamaskSession();
    // console.log(store.tradeVars)
    // console.log("change")
  }, [loading]);

  return (
    <div className="">
      <div
        className="h-full border border-8 rounded-xl md:container md:mx-auto"
        style={{
          height: '100vh',
          backgroundImage: `url(${mainback_1})`,
          backgroundRepeat: 'no-repeat',
          backgroundAttachment: 'fixed',
          backgroundSize: 'cover',
          flexDirection: 'row',
          display: 'flex',
          maxWidth: '1550',
          minWidth: '1330',
          maxHeight: '980',
        }}
      >
        {/*left side*/}
        <div className="flex justify-center w-1/2 align-center">
          {/*placeOrder*/}
          <div className="flex flex-col justify-around w-full h-5/6">
            <PlaceOrder />
          </div>
        </div>
        {/*right side*/}
        <div className="flex w-5/6">
          <div className="flex flex-col justify-between w-full pr-12 align-middle h-5/6 ">
            <div className="flex justify-between items-around">
              {/* Left side */}
              <div
                style={{
                  width: '40%',
                  height: '200px',
                  backgroundImage: `url(${top_right})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'contain',
                  flexDirection: 'row',
                  position: 'absolute',
                  display: 'flex',
                  right: 200,
                  zIndex: 100,
                }}
                className="justify-center align-center "
              >
                <div onClick={() => setChange(!change)} className="flex justify-center h-full align-center">
                  {store.showTransactionDetails ? (
                    <div style={{ flexDirection: 'column', justifyContent: 'space-evenly' }}>
                      <p>Product: {store.tradeVars.currency}-USDC </p>
                      <p>Direction: {store.tradeVars.longShort}</p>
                      <p>Entry Price: $ {store.transactionDetails.entryPrice}</p>
                      <p>Current Price: $ {store.transactionDetails.currentPrice}</p>
                      {store.transactionDetails.isProfitable && (
                        <p style={{ color: 'green' }}>PnL: ${store.transactionDetails.PnL}</p>
                      )}
                      {!store.transactionDetails.isProfitable && (
                        <p style={{ color: 'red' }}>PnL: ${store.transactionDetails.PnL}</p>
                      )}
                    </div>
                  ) : null}
                </div>
              </div>
              <div></div>
              {/* Right side */}
              <div
                style={{
                  backgroundImage: `url(${state})`,
                  backgroundRepeat: 'no-repeat',
                  backgroundSize: 'cover',
                  //position: 'absolute',
                  width: '160px',
                  height: '80px',
                  backgroundPositionY: 'bottom',
                  right: 150,
                  top: 50,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                }}
              >
                <button
                  type="button"
                  onClick={() => setModalOpen(true)}
                  data-modal-toggle="crypto-modal"
                  className="w-full h-full p-2 text-2xl font-extrabold text-orange-500 bg-transparent rounded btn border-slate-400 "
                >
                  {isConnected ? address.slice(0, 5) + '...' : 'Connect'}
                </button>
              </div>
            </div>

            <Graph />
            <Reimburse />

            <div
              style={{
                backgroundImage: `url(${bottom})`,
                width: '30%',
                height: '20vh',
                backgroundRepeat: 'no-repeat',
                backgroundSize: 'contain',
                position: 'absolute',
                display: 'flex',
                right: '17%',
                backgroundClip: 'border-box',
                top: '78vh',
              }}
            ></div>
          </div>
        </div>
      </div>

      <ConnectWallet open={setModalOpen} isOpen={modalOpen} />
    </div>
  );
}

export default observer(App);
