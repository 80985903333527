import { ethers } from 'ethers';

import { network } from '../constants';

let _provider = new ethers.providers.JsonRpcProvider('https://arb1.arbitrum.io/rpc');
global._provider = _provider;
let chainId = 42161;
let _walletConnect;

export async function checkMetamaskSession() {
  if (window.ethereum) connectMetamask(true);
}

export async function connectMetamask(resume) {
  let metamask = window.ethereum;
  if (!metamask && !resume) return alert('Metamask is not installed.');

  _provider = new ethers.providers.Web3Provider(metamask);

  let accounts;
  if (resume) {
    accounts = await _provider.send('eth_accounts');
  } else {
    accounts = await _provider.send('eth_requestAccounts', []);
  }

  if (!accounts || !accounts.length) return;
  const network_ = await _provider.getNetwork();

  //switch chain of not on arbitrum
  if (network_.chainId !== chainId) {
    switchMetamaskChain();
  }

  metamask.on('chainChanged', (_chainId) => {
    window.location.reload();
  });

  if (accounts.length) {
    handleAccountsChanged();
  }
  metamask.on('accountsChanged', handleAccountsChanged);
}

export async function switchMetamaskChain() {
  let metamask = window.ethereum;
  try {
    await metamask.request({
      method: 'wallet_switchEthereumChain',
      params: [{ chainId: ethers.utils.hexlify(chainId) }],
    });
  } catch (switchError) {
    // This error code indicates that the chain has not been added to MetaMask.
    if (switchError.code === 4902) {
      try {
        await metamask.request({
          method: 'wallet_addEthereumChain',
          params: [
            {
              chainId: ethers.utils.hexlify(chainId),
              chainName: network.label,
              rpcUrls: [network.rpc],
              nativeCurrency: {
                name: 'ETH',
                symbol: 'ETH',
                decimals: 18,
              },
              blockExplorerUrls: [network.explorer],
            },
          ],
        });
      } catch (addError) {
        alert('Error Adding Arbitrum to metamask. You can add it manually to metamask');
      }
    } else {
      alert('Unable to switch accounts');
    }
  }
}

export async function disconnectWallet(force) {
  if (force && _walletConnect) await _walletConnect.disconnect();
}

async function handleAccountsChanged() {
  const _signer = _provider.getSigner();
  //const _address = await _signer.getAddress();
  global._signer = _signer; //would use store in subsiqu
  //console.log(_address);
  // amplitude.getInstance().setUserId(_address);
}

export const mask = (value) => {
  value = value.replace('.', '').replace(',', '').replace(/\D/g, '');

  const options = { minimumFractionDigits: 2 };
  const result = new Intl.NumberFormat('en-US', options).format(parseFloat(value) / 100);

  //console.log(result);
  const maskedResult = '$ ' + result;
  return { maskedResult, result };
};
