import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { leverage } from '../../assets';
import { StoreContext } from '../../utils/store';

function Leverage() {
  const store = useContext(StoreContext);
  const { tradeVars } = store;

  return (
    <div className="flex items-center justify-center w-full py-6">
      <div
        className="flex flex-row items-center justify-around"
        style={{
          backgroundImage: `url(${leverage})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'contain',
          flexDirection: 'row',
          backgroundPosition: 'center',
          display: 'flex',
          maxWidth: 600,
          width: 500,
          height: 200,
          backgroundColor: 'transparent',
        }}
      >
        <div
          style={{
            backgroundColor: tradeVars.leverage === 5 ? 'gold' : 'transparent',
          }}
          className="flex items-center justify-end w-1/3 h-28"
        >
          <h1 className="text-2xl font-extrabold">5x</h1>
        </div>

        <div
          style={{
            backgroundColor: tradeVars.leverage === 15 ? 'gold' : 'transparent',
          }}
          className="flex items-center justify-center w-1/3 h-28"
        >
          <h1 className="text-2xl font-extrabold">15x</h1>
        </div>

        <div
          style={{
            marginRight: 20,
            backgroundColor: tradeVars.leverage === 50 ? 'gold' : 'transparent',
          }}
          className="flex items-center justify-start w-1/3 h-28 "
        >
          <h1 className="text-2xl font-extrabold">50x</h1>
        </div>
      </div>
    </div>
  );
}

export default observer(Leverage);
