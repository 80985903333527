import { observer } from 'mobx-react-lite';
import React, { useContext } from 'react';
import { left_back, rect, timerback } from '../../assets';
import { StoreContext } from '../../utils/store';
import BetAmount from '../BetAmount';
import Leverage from '../Leverage';

function PlaceOrder() {
  const store = useContext(StoreContext);

  return (
    <div>
      <BetAmount />

      <div
        style={{
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          display: 'flex',
          backgroundColor: 'transparent',
        }}
        className="flex flex-col items-center justify-center"
      >
        <Leverage />

        <div className="flex justify-center w-full " style={{ flexDirection: 'row' }}>
          <div
            style={{
              backgroundImage: `url(${rect})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              flexDirection: 'row',
              backgroundPosition: 'center',
              alignItems: 'center',
              width: 200,
              height: 100,
              backgroundColor: 'transparent',
            }}
            className="flex justify-center p-4 text-center"
          >
            <h4
              style={{
                backgroundColor: store.tradeVars.currency === 'BTC' ? 'gold' : 'transparent',
              }}
              className="text-2xl font-extrabold"
            >
              BTC
            </h4>
          </div>
          <div
            style={{
              backgroundImage: `url(${rect})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              flexDirection: 'row',
              backgroundPosition: 'center',
              alignItems: 'center',
              width: 200,
              height: 100,
              backgroundColor: 'transparent',
            }}
            className="flex justify-center p-4 text-center lex"
          >
            <h4
              style={{
                backgroundColor: store.tradeVars.currency === 'ETH' ? 'gold' : 'transparent',
              }}
              className="text-2xl font-extrabold"
            >
              ETH{' '}
            </h4>
          </div>
        </div>
        <div className="flex justify-around" style={{ flexDirection: 'row', maxWidth: 500, width: 400 }}>
          <div
            style={{
              backgroundImage: `url(${rect})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              flexDirection: 'row',
              alignItems: 'center',
              width: 200,
              height: 100,
              backgroundPosition: 'center',
              backgroundColor: 'transparent',
            }}
            className="flex justify-center p-4 text-center lex"
          >
            <h4
              style={{
                backgroundColor: store.tradeVars.longShort === 'Long' ? 'gold' : 'transparent',
              }}
              className="text-2xl font-extrabold"
            >
              LONG{' '}
            </h4>
          </div>
          <div
            style={{
              backgroundImage: `url(${rect})`,
              backgroundRepeat: 'no-repeat',
              backgroundSize: 'contain',
              flexDirection: 'row',
              alignItems: 'center',
              width: 200,
              height: 100,
              backgroundPosition: 'center',
              backgroundColor: 'transparent',
            }}
            className="flex justify-center p-4 text-center lex"
          >
            <h4
              style={{
                backgroundColor: store.tradeVars.longShort === 'Short' ? 'gold' : 'transparent',
              }}
              className="text-2xl font-extrabold"
            >
              SHORT{' '}
            </h4>
          </div>
        </div>

        <div
          style={{
            backgroundImage: `url(${timerback})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            flexDirection: 'row',
            width: 200,
            height: 90,
            backgroundPosition: 'center',
            display: 'flex',
            backgroundColor: 'transparent',
          }}
          className="flex items-center justify-center "
        >
          <div className="flex flex-row items-center justify-between" style={{ width: 120 }}>
            <div
              style={{
                backgroundColor: store.tradeVars.time === 20 ? 'gold' : 'transparent',
              }}
              className="flex text-center "
            >
              20s
            </div>
            <div
              style={{
                backgroundColor: store.tradeVars.time === 30 ? 'gold' : 'transparent',
              }}
              className="flex text-center "
            >
              30s
            </div>
            <div
              style={{
                backgroundColor: store.tradeVars.time === 45 ? 'gold' : 'transparent',
              }}
              className="flex text-center "
            >
              45s
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default observer(PlaceOrder);
