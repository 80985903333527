import React, { createContext } from 'react';
import { useLocalObservable } from 'mobx-react-lite';

export const StoreContext = createContext();

export const StoreProvider = ({ children }) => {
  const store = useLocalObservable(() => ({
    /*observables here*/
    /*actions here*/
    /*computed values i.e. derived state here*/
    trades: [],
    loading: false,
    leverage: [5, 15, 50],
    currency: ['BTC', 'ETH'],
    longShort: ['Long', 'Short'],
    time: [20, 30, 45],
    amount: 0,
    factoryTradeId: null,
    reimburse: false,
    showTransactionDetails: false,
    transactionDetails: {
      size: 0,
      margin: 0,
      entryPrice: 0,
      currentPrice: 0,
      isProfitable: false,
      PnL: 0,
    },
    tradeVars: {
      leverage: 0,
      time: 0,
      amount: 0,
      currency: '',
      longShort: '',
    },

    setStoreState(state, value) {
      console.log('Store', state, value);
      this[state] = value;
    },
    setTransactionDetails(value) {
      console.log('Value: ', value);
      this.transactionDetails = {
        size: value.size,
        margin: value.margin,
        entryPrice: value.entryPrice,
        currentPrice: value.currentPrice,
        isProfitable: value.isProfitable,
        PnL: value.PnL,
      };
    },
    addTrade(current_trade) {
      this.trade = [...this.trade, current_trade];
    },

    myTrades() {
      return this.trade;
    },

    getTradeVars() {
      return this.tradeVars;
    },

    doRandomizeTradeVars() {
      //randomize leverage
      this.tradeVars.leverage = this.leverage[Math.floor(Math.random() * this.leverage.length)];
      //randomize currency
      this.tradeVars.currency = this.currency[Math.floor(Math.random() * this.currency.length)];
      //randomize longshort
      this.tradeVars.longShort = this.longShort[Math.floor(Math.random() * this.longShort.length)];
      //randomize time
      this.tradeVars.time = this.time[Math.floor(Math.random() * this.time.length)];
      this.tradeVars.amount = this.amount;
      this.loading = !this.loading;
    },
  }));
  return <StoreContext.Provider value={store}>{children}</StoreContext.Provider>;
};
