import React, { useContext } from 'react';
import { graph } from '../../assets';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../utils/store';
import { contract_address, abi } from '../../constants';
import { useSigner, useContract, useAccount } from 'wagmi';

export default observer(function Graph() {
  const { store } = useContext(StoreContext);
  const { data: signer } = useSigner();
  const { address } = useAccount();

  const betContract = useContract({
    addressOrName: contract_address,
    contractInterface: abi,
    signerOrProvider: signer,
  });

  const getPositionData = async () => {
    if (store.factoryTradeId) {
      const tx = await betContract.getPosition(address, store.factoryTradeId);
      console.log('Position Data', await tx.wait());
    }
  };
  return (
    <div
      style={{
        backgroundImage: `url(${graph})`,
        width: '55%',
        height: '70vh',
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'contain',
        position: 'absolute',
        right: 40,
        top: 110,
      }}
      className="relative flex items-center justify-center w-full h-4/5 "
    >
      <div className="w-full p-8 h-4/5" onClick={() => getPositionData()}></div>
    </div>
  );
});
