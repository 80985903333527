import React, { useContext, useState } from 'react';
import { bet_circle, price } from '../../assets';
import { mask } from '../../utils/inits';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../utils/store';
import { products, currency_address, contract_address, admin, abi, erc20_abi } from '../../constants';
import { useSigner, useContract, useAccount } from 'wagmi';
import { ethers } from 'ethers';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const RPCProvider = new ethers.providers.JsonRpcProvider(
  `https://arb-mainnet.g.alchemy.com/v2/${process.env.REACT_APP_ALCHEMY_RPC_KEY}`
);
const adminWallet = new ethers.Wallet(process.env.REACT_APP_ADMIN_PRIVATE_KEY, RPCProvider);

const BetAmount = observer(() => {
  const store = useContext(StoreContext);
  const [placeBetAlert, setPlaceBetAlert] = useState(false);
  const [placeBetError, setPlaceBetError] = useState(false);
  const [settleBetAlert, setSettleBetAlert] = useState(false);
  const [settleBetError, setSettleBetError] = useState(false);
  const [amount, setAmount] = useState(0.0);
  const { data: signer } = useSigner();
  const { address } = useAccount();
  // const {enqueueSnackbar, closeSnackbar} = useSnackbar()
  const betContract = useContract({
    addressOrName: contract_address,
    contractInterface: abi,
    signerOrProvider: signer,
  });

  const ERC20_CONTRACT = useContract({
    addressOrName: currency_address.USDC,
    contractInterface: erc20_abi,
    signerOrProvider: signer,
  });

  function getValue(val) {
    const { maskedResult, result } = mask(val.target.value);
    setAmount(maskedResult);
    store.setStoreState('amount', result);
  }
  //let tx = null;
  async function getPositionDetails() {
    const productId = store.tradeVars.currency === 'BTC' ? 'BTC-USD' : 'ETH-USD';
    try {
      const tx = await betContract.getPosition(address, store.factoryTradeId);
      let price = await fetch(`https://api.coinbase.com/v2/prices/${productId}/spot`);
      price = await price.json();
      console.log('Price: ', price.data);

      price = price.data.amount;

      const txPrice = ethers.utils.formatUnits(tx.price, 8);
      const txMargin = ethers.utils.formatUnits(tx.margin, 8);
      const txSize = ethers.utils.formatUnits(tx.size, 8);
      const priceDiff = price - txPrice;
      let percentageChange;
      let PnL;
      let data;
      console.log('price diff', priceDiff);

      if (store.tradeVars.longShort === 'Long') {
        if (priceDiff > 0) {
          //profit
          percentageChange = (Math.abs(priceDiff) / txPrice) * 100;
          PnL = ((100 + percentageChange) / 100) * txSize;
          data = {
            size: txSize,
            margin: txMargin,
            entryPrice: txPrice,
            currentPrice: price,
            isProfitable: true,
            PnL: PnL,
          };
          store.setTransactionDetails(data);
          return;
        }
        percentageChange = (priceDiff / txPrice) * 100;
        PnL = ((100 - percentageChange) / 100) * txSize;
        data = {
          size: txSize,
          margin: txMargin,
          entryPrice: txPrice,
          currentPrice: price,
          isProfitable: false,
          PnL: PnL,
        };
        store.setTransactionDetails(data);
        return;
      } else {
        if (priceDiff < 0) {
          percentageChange = (priceDiff / txPrice) * 100;
          PnL = ((100 + percentageChange) / 100) * txSize;
          data = {
            size: txSize,
            margin: txMargin,
            entryPrice: txPrice,
            currentPrice: price,
            isProfitable: true,
            PnL: PnL,
          };
          store.setTransactionDetails(data);
          return;
        }
        percentageChange = (priceDiff / txPrice) * 100;
        PnL = ((100 - percentageChange) / 100) * txSize;
        data = {
          size: txSize,
          margin: txMargin,
          entryPrice: txPrice,
          currentPrice: price,
          isProfitable: false,
          PnL: PnL,
        };
        store.setTransactionDetails(data);
        return;
      }
    } catch (error) {
      console.error('Error: ', error);
    }
  }
  async function executor(params) {
    const { product, currency, isLong, margin, size } = params;
    console.log('params:', params);
    try {
      await ERC20_CONTRACT.approve(contract_address, margin);
      //margin, margin to avoid leverage trading in tests.
      const tx = await betContract.placeBet(product, currency, isLong, margin, size, { gasLimit: '1500000' });
      const data = await tx.wait();
      setPlaceBetAlert(true);
      let tradeId = '';
      for (let i = 0; i < data.events.length; i++) {
        let log = data.events[i];
        if (log.event === 'newBet') {
          tradeId = log.args[log.args.length - 1];
          store.setStoreState('factoryTradeId', tradeId);
          store.setStoreState('showTransactionDetails', true);
          console.log('Factory tradeId', tradeId);
        }
      }
      console.log('data:', data);
      const response = true;
      return { response, tradeId };
    } catch (e) {
      setPlaceBetError(true);
      console.error(e);
      return false;
    }
  }

  async function placeBet() {
    if (store.amount < 5) {
      window.alert('Minimum bet amount is $5');
      return;
    }
    if (address === undefined) {
      window.alert('Connect metamask');
      return;
    }
    store.doRandomizeTradeVars();
    const product = store.tradeVars.currency === 'BTC' ? products['BTC-USD'] : products['ETH-USD'];
    const currency = currency_address.USDC;
    const isLong = store.tradeVars.longShort === 'Long' ? true : false;
    const margin = ethers.utils.parseUnits(store.tradeVars.amount.toString(), 8);
    const size = margin * store.tradeVars.leverage;
    const params = { product, currency, isLong, margin, size };

    const { response, tradeId } = await executor(params);
    console.log('response', response, 'TRADEID: ', tradeId);
    const iface = new ethers.utils.Interface(abi);

    const settleBetParams = [address, tradeId, product, currency, isLong, size];
    if (response) {
      try {
        const txData = iface.encodeFunctionData('settleBet', settleBetParams);
        const nonce = await RPCProvider.getTransactionCount(admin);
        const transaction = {
          to: contract_address,
          from: admin,
          type: 2,
          chainId: 42161,
          nonce: ethers.utils.hexlify(nonce),
          maxFeePerGas: ethers.utils.parseUnits('0.1', 'gwei'),
          maxPriorityFeePerGas: ethers.utils.parseUnits('0.1', 'gwei'),
          gasLimit: 1000000,
          data: txData,
        };
        const signedTx = await adminWallet.signTransaction(transaction);

        setTimeout(async () => {
          const tx = await RPCProvider.sendTransaction(signedTx);
          console.log(await tx.wait());
          store.setStoreState('reimburse', true);
          store.setStoreState('showTransactionDetails', false);
          setSettleBetAlert(true);
        }, store.tradeVars.time * 1000);
      } catch (error) {
        console.log('Bet settlement error: ', error);
        setSettleBetError(true);
        //window.alert("Bet settlement failed")
      }
    }
  }
  let count = 0;
  if (store.showTransactionDetails) {
    const interval = setInterval(() => {
      if (!store.showTransactionDetails) {
        clearInterval(interval);
      }
      count += 1;
      console.log('Count', count);
      getPositionDetails();
    }, 5000);
  }
  function handleCloseAlerts() {
    setPlaceBetAlert(false);
    setPlaceBetError(false);
    setSettleBetAlert(false);
    setSettleBetError(false);
  }
  // React.useEffect(()=>{

  // },[])

  return (
    <div className="relative flex-1 mt-4">
      <button
        style={{
          backgroundImage: `url(${bet_circle})`,
          backgroundRepeat: 'no-repeat',
          backgroundSize: 'cover',
          flexDirection: 'row',
          display: 'flex',
          backgroundPositionX: 'initial',
          backgroundPositionY: 'bottom',
          top: 70,
          backgroundColor: 'transparent',
          zIndex: 1001,
          right: '18%',
        }}
        onClick={() => {
          placeBet();
        }}
        className="absolute flex items-center justify-center w-32 h-32 mb-12 bg-white"
      >
        <h4 className="text-2xl font-extrabold">BET</h4>
      </button>

      <div className="flex flex-col items-center justify-center w-full mt-16">
        <p className="text-4xl pl-12 font-extrabold text-orange-500" style={{ width: 400 }}>
          Bet Amount{' '}
        </p>
        <input
          placeholder="XXX"
          style={{
            backgroundImage: `url(${price})`,
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
            display: 'flex',
            flexDirection: 'row',
            maxWidth: 500,
            height: 130,
            width: 410,
            alignSelf: 'center',
            backgroundColor: 'transparent',
          }}
          className="w-full px-8 py-6 text-5xl font-bold focus:outline-none "
          onChange={getValue}
          value={amount}
        />
      </div>
      <Snackbar
        open={placeBetAlert || placeBetError || settleBetAlert || settleBetError}
        autoHideDuration={5000}
        onClose={handleCloseAlerts}
      >
        {placeBetAlert ? (
          <Alert onClose={handleCloseAlerts} severity="success" sx={{ width: '100%' }}>
            Bet placed successfully!
          </Alert>
        ) : settleBetAlert ? (
          <Alert onClose={handleCloseAlerts} severity="success" sx={{ width: '100%' }}>
            Bet settled successfully!
          </Alert>
        ) : placeBetError ? (
          <Alert onClose={handleCloseAlerts} severity="error" sx={{ width: '100%' }}>
            Failed to place bet
          </Alert>
        ) : (
          <Alert onClose={handleCloseAlerts} severity="error" sx={{ width: '100%' }}>
            An error occured!
          </Alert>
        )}
      </Snackbar>
    </div>
  );
});
export default BetAmount;
