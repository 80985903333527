const abi = [
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'previousOwner',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'OwnershipTransferred',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'initiator',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'proxyTradeId',
        type: 'bytes32',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'factoryTradeId',
        type: 'bytes32',
      },
    ],
    name: 'newBet',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'initiator',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'address',
        name: 'proxyAddress',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'bytes32',
        name: 'factoryTradeId',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'bytes32',
        name: 'productId',
        type: 'bytes32',
      },
      {
        indexed: false,
        internalType: 'string',
        name: 'reason',
        type: 'string',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'size',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'uint256',
        name: 'time',
        type: 'uint256',
      },
      {
        indexed: false,
        internalType: 'bool',
        name: 'isLiquidated',
        type: 'bool',
      },
    ],
    name: 'settleBetFailure',
    type: 'event',
  },
  {
    anonymous: false,
    inputs: [
      {
        indexed: true,
        internalType: 'address',
        name: 'initiator',
        type: 'address',
      },
      {
        indexed: true,
        internalType: 'uint64',
        name: 'price',
        type: 'uint64',
      },
      {
        indexed: true,
        internalType: 'uint64',
        name: 'timestamp',
        type: 'uint64',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'margin',
        type: 'uint64',
      },
      {
        indexed: false,
        internalType: 'uint64',
        name: 'size',
        type: 'uint64',
      },
    ],
    name: 'settledBet',
    type: 'event',
  },
  {
    inputs: [],
    name: 'owner',
    outputs: [
      {
        internalType: 'address',
        name: '',
        type: 'address',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [],
    name: 'renounceOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'newOwner',
        type: 'address',
      },
    ],
    name: 'transferOwnership',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'count',
        type: 'uint16',
      },
    ],
    name: 'createProxies',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_productId',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: '_currency',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: '_isLong',
        type: 'bool',
      },
      {
        internalType: 'uint256',
        name: '_margin',
        type: 'uint256',
      },
      {
        internalType: 'uint256',
        name: '_size',
        type: 'uint256',
      },
    ],
    name: 'placeBet',
    outputs: [
      {
        internalType: 'bytes32',
        name: '',
        type: 'bytes32',
      },
    ],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address',
      },
      {
        internalType: 'bytes32',
        name: '_factoryTradeId',
        type: 'bytes32',
      },
      {
        internalType: 'bytes32',
        name: '_productId',
        type: 'bytes32',
      },
      {
        internalType: 'address',
        name: '_currency',
        type: 'address',
      },
      {
        internalType: 'bool',
        name: '_isLong',
        type: 'bool',
      },
      {
        internalType: 'uint256',
        name: '_size',
        type: 'uint256',
      },
    ],
    name: 'settleBet',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'bytes32',
        name: '_factoryTradeId',
        type: 'bytes32',
      },
    ],
    name: 'reimburseUser',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint256',
        name: 'amount',
        type: 'uint256',
      },
    ],
    name: 'withdraw',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: 'user',
        type: 'address',
      },
    ],
    name: 'withdrawStuckFunds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint16',
        name: 'id',
        type: 'uint16',
      },
    ],
    name: 'withdrawStuckProxyFunds',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address',
      },
      {
        internalType: 'bytes32',
        name: '_factoryTradeId',
        type: 'bytes32',
      },
    ],
    name: 'getPosition',
    outputs: [
      {
        components: [
          {
            internalType: 'uint64',
            name: 'size',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'margin',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'timestamp',
            type: 'uint64',
          },
          {
            internalType: 'uint64',
            name: 'price',
            type: 'uint64',
          },
        ],
        internalType: 'struct ICapFinance.Position',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'address',
        name: '_user',
        type: 'address',
      },
      {
        internalType: 'bytes32',
        name: '_factoryTradeId',
        type: 'bytes32',
      },
    ],
    name: 'getBetDetails',
    outputs: [
      {
        components: [
          {
            internalType: 'uint256',
            name: 'margin',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'size',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'leverage',
            type: 'uint256',
          },
          {
            internalType: 'uint256',
            name: 'timestamp',
            type: 'uint256',
          },
          {
            internalType: 'bool',
            name: 'isLong',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'isActiveTrade',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'isLiquidated',
            type: 'bool',
          },
          {
            internalType: 'bool',
            name: 'isReimbursed',
            type: 'bool',
          },
          {
            internalType: 'address',
            name: 'currency',
            type: 'address',
          },
          {
            internalType: 'address',
            name: 'initiator',
            type: 'address',
          },
          {
            components: [
              {
                internalType: 'uint16',
                name: 'id',
                type: 'uint16',
              },
              {
                internalType: 'address',
                name: 'proxyAddress',
                type: 'address',
              },
              {
                internalType: 'bool',
                name: 'isAvailable',
                type: 'bool',
              },
            ],
            internalType: 'struct Factory.Proxies',
            name: 'proxyContract',
            type: 'tuple',
          },
          {
            internalType: 'bytes32',
            name: 'productId',
            type: 'bytes32',
          },
          {
            internalType: 'bytes32',
            name: 'proxyTradeId',
            type: 'bytes32',
          },
          {
            internalType: 'bytes32',
            name: 'factoryTradeId',
            type: 'bytes32',
          },
        ],
        internalType: 'struct Factory.Trade',
        name: '',
        type: 'tuple',
      },
    ],
    stateMutability: 'view',
    type: 'function',
  },
  {
    inputs: [
      {
        internalType: 'uint64',
        name: 'newFee',
        type: 'uint64',
      },
    ],
    name: 'modifyFee',
    outputs: [],
    stateMutability: 'nonpayable',
    type: 'function',
  },
];

const erc20_abi = [
  'function totalSupply() view returns (uint256)',
  'function decimals() view returns (uint8)',
  'function balanceOf(address account) view returns (uint256)',
  'function transfer(address recipient, uint256 amount) returns (bool)',
  'function allowance(address owner, address spender) view returns (uint256)',
  'function approve(address spender, uint256 amount) returns (bool)',
];
const contract_address = '0x09928d18d4500898df21ab17b3f011980316dc96'; //Factory contract
const admin = '0x4264976b7975035a1A27a9940020417cfC24B7D5';

const products = {
  'ETH-USD': '0x4554482d55534400000000000000000000000000000000000000000000000000',
  'BTC-USD': '0x4254432d55534400000000000000000000000000000000000000000000000000',
};

const currency_address = {
  ETH: '0x0000000000000000000000000000000000000000',
  USDC: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
};

const ADDRESS_ZERO = '0x0000000000000000000000000000000000000000';

const network = {
  label: 'Arbitrum',
  router: '0x5ABFF8F8D5b13253dCAB1e427Fdb3305cA620119',
  explorer: 'https://arbiscan.io',
  rpc: 'https://arb1.arbitrum.io/rpc', // for walletconnect
  currencies: {
    weth: ADDRESS_ZERO,
    usdc: '0xff970a61a04b1ca14834a43f5de4533ebddb5cc8',
  },
  poolInception: {
    weth: 1637154307000,
    usdc: 1637154307000,
    cap: 1637154307000,
  },
  cap: '0x031d35296154279DC1984dCD93E392b1f946737b',
};

export { abi, contract_address, admin, products, currency_address, network, erc20_abi };
