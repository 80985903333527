import React, { useState, useContext } from 'react';
import { observer } from 'mobx-react-lite';
import { StoreContext } from '../../utils/store';
import { state } from '../../assets';
import { contract_address, abi } from '../../constants';
import { useSigner, useContract } from 'wagmi';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';

const Reimburse = observer(() => {
  const store = useContext(StoreContext);

  const [disabled, setDisabled] = useState(true);
  const [open, setOpen] = useState(false);
  const [withdrawn, setWithdrawn] = useState(false);
  const [withdrawalError, setWithdrawalError] = useState(false);
  const { data: signer } = useSigner();

  const betContract = useContract({
    addressOrName: contract_address,
    contractInterface: abi,
    signerOrProvider: signer,
  });
  if (store.reimburse) {
    setTimeout(() => {
      setDisabled(false);
      if (!open) {
        setOpen(true);
      }
    }, 10000); //10s
  }
  const reimburser = async () => {
    try {
      const tx = await betContract.reimburseUser(store.factoryTradeId, { gasLimit: 1000000 });
      await tx.wait();
      store.setStoreState('reimburse', false);
      setDisabled(true);
      setWithdrawn(true);
    } catch (error) {
      // setDisabled(false);
      // setWithdrawalError(true);
      console.log('Reimburser error: ', error);
    }
  };
  const handleOpenClose = () => {
    setOpen(false);
  };
  const handleCloseWithdrawn = () => {
    setWithdrawn(false);
    setOpen(false);
  };
  const handleCloseError = () => {
    setWithdrawalError(false);
  };

  return (
    <div
      style={{
        backgroundImage: `url(${state})`,
        backgroundRepeat: 'no-repeat',
        backgroundSize: 'cover',
        width: '160px',
        height: '80px',
        backgroundPositionY: 'bottom',
        right: 150,
        top: 50,
        textAlign: 'center',
        alignSelf: 'flex-end',
        alignContent: 'center',
        marginTop: 20,
      }}
    >
      <button
        type="button"
        style={{ fontSize: 20 }}
        onClick={() => {
          reimburser();
        }}
        disabled={disabled}
        className="w-full h-full p-2 text-2xl font-extrabold text-orange-500 bg-transparent rounded btn border-slate-400"
      >
        Withdraw
      </button>
      <Snackbar open={open || withdrawn || withdrawalError} autoHideDuration={5000} onClose={handleOpenClose}>
        {open ? (
          <Alert onClose={handleOpenClose} severity="success" sx={{ width: '100%' }}>
            Funds available for withdrawal
          </Alert>
        ) : withdrawn ? (
          <Alert onClose={handleCloseWithdrawn} severity="success" sx={{ width: '100%' }}>
            Funds withdrawn successfully
          </Alert>
        ) : (
          <Alert onClose={handleCloseError} severity="error" sx={{ width: '100%' }}>
            Failed to withdraw funds
          </Alert>
        )}
      </Snackbar>
    </div>
  );
});
export default Reimburse;
